import React from "react";
import {connect} from "react-redux";

import {setActiveStepIndex, answerFinalQuestion} from "../../../storage/actions/Steps";

class NavigationButtonsComponent extends React.Component{

    prevStep = () => {
        let prev_index = this.props.active_step_index > 0 ? Number(this.props.active_step_index) -1  : 0;
        this.props.setStepIndex(prev_index);
    }
    nextStep = () => {
        let next_index = (this.props.steps_number -1)  > this.props.active_step_index ? Number(this.props.active_step_index) +1  : 'final';
        if( (this.props.steps_number -1)  > this.props.active_step_index ) {
            this.props.setStepIndex(Number(this.props.active_step_index) +1);
        }else {
            this.doFinalAction();
        }
    }
    doFinalAction = () => {this.props.doFinal();}

    render() {
        return (
            <div className="button_wrapper">

                {
                    (this.props.active_step_index > 0) &&
                    <button className="button button_prev js-prev" onClick={this.prevStep}>
                        <svg width="7" height="18" viewBox="0 0 7 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.87715 9.71046L6.83336 4.75425L6.07911 4L0.368652 9.71046L6.07911 15.4209L6.83336 14.6667L1.87715 9.71046Z" fill="#3C3C3C"/>
                        </svg>
                        <span>Назад</span>
                    </button>
                }

                <button className="button button_next js-next" disabled={!this.props.next_step_allowed && " disabled"} onClick={this.nextStep}>
                    <span>Далее</span>
                    <svg width="7" height="18" viewBox="0 0 7 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.12285 9.71046L0.166644 4.75425L0.920891 4L6.63135 9.71046L0.920891 15.4209L0.166644 14.6667L5.12285 9.71046Z" fill="white"/>
                    </svg>
                </button>

            </div>
        );
    }
}

let NavigationButtons = connect(
    state => {return {
        active_step_index: state.active_step_index,
        next_step_allowed: state.next_step_allowed,
        steps_number: state.steps.length
    }},
    dispatch => {return{
        setStepIndex: (index) => {dispatch(setActiveStepIndex(index))},
        doFinal: () => {dispatch(answerFinalQuestion())}
    }}
)(NavigationButtonsComponent);

export default NavigationButtons;
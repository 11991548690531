import LastNameField from "./Fields/LastNameField";
import FirstNameField from "./Fields/FirstNameField";
import SecondNameField from "./Fields/SecondNameField";

import BirthdayDateField from "./Fields/BirthdayDateField";

import VacanciesField from "./Fields/VacanciesField";

import CityField from "./Fields/CityField";

import PhoneField from "./Fields/PhoneField";
import CommentField from "./Fields/CommentField";
import RationField from "./Fields/RationField";
import RoomField from "./Fields/RoomField";
import LeaveDateField from "./Fields/LeaveDateField";
import ArrivalDateField from "./Fields/ArrivalDateField";
import AdultField from "./Fields/AdultField";
import ChildrenField from "./Fields/ChildrenField";

import IntroField from "./Fields/IntroField";
import LoyaltyField from "./Fields/LoyaltyField";

export const last_name = LastNameField;
export const first_name = FirstNameField;
export const second_name = SecondNameField;

export const birthday_date = BirthdayDateField;

export const vacancies = VacanciesField;

export const city = CityField;

export const tel = PhoneField;
export const room = RoomField;
export const ration = RationField;
export const comment = CommentField;
export const arrival = ArrivalDateField;
export const leave = LeaveDateField;
export const adult = AdultField;
export const children = ChildrenField;

export const intro = IntroField;
export const loyalty = LoyaltyField;
import React from "react";

import room from './room.jpg';
import villa from './villa.jpg';

export default class RoomField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.field_value ?? null,
            is_valid: Boolean(props.field_value)
        }
    }

    localValueChange = (event) => {
        let val = event.target.defaultValue,
            valid = this.validateValue(val);

        this.setState((state, props) => {
            if( valid ) {
                this.props.changeField( this.props.field_name, val );
            }else if( valid === false && state.is_valid === true ) {
                this.props.changeField( this.props.field_name, null );
            }

            return {value: val, is_valid: valid};
        });
    };

    validateValue = (value) => {
        return Boolean( value === 'Номер' || value === 'Вилла' );
    }

    render() {
        return(
            <React.Fragment>
                <div className="room__wrapper">
                    <label className="room__label">
                        <input type={'radio'} onChange={this.localValueChange} name={this.props.field_name} value="Номер" checked={Boolean(this.state.value === "Номер")} required />
                        <div className="room_image">
                            <img src={room} />
                            <span>Номер</span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2ZM10.2929 5.29289L7 8.58579L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289Z" fill="#27AE60"/>
                            </svg>
                        </div>
                    </label>
                    <label className="room__label">
                        <input type={'radio'} onChange={this.localValueChange} name={this.props.field_name} value="Вилла" checked={Boolean(this.state.value === "Вилла")} required />
                        <div className="room_image">
                            <img src={villa} />
                            <span>Вилла</span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2ZM10.2929 5.29289L7 8.58579L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289Z" fill="#27AE60"/>
                            </svg>
                        </div>
                    </label>
                    <span className="validity">Ошибка</span>
                </div>
            </React.Fragment>
        );
    }
}
import React from "react";

export default class AdultField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.field_value ? Number(props.field_value) : 1,
            is_valid: Boolean(props.field_value > 0)
        }
    }
    componentDidMount() {
        this.props.changeField( this.props.field_name, this.state.value);
    }

    setValue = (val) => {
        val = this.prepareValue(val);
        this.setState({value: val});
        this.props.changeField( this.props.field_name, val);
    };

    prepareValue = (val) => {
        val = Number(val);
        if( !val || val < 2 ) val = 1;
        return val;
    }

    localValueChange = (event) => {
        let val = this.prepareValue(event.target.value),
            valid = this.validateValue(val);

        this.setState((state, props) => {
            if( valid ) {
                this.props.changeField( this.props.field_name, val );
            }else if( valid === false && state.is_valid === true ) {
                this.props.changeField( this.props.field_name, null );
            }

            return {value: val, is_valid: valid};
        });
    };

    validateValue = (value) => {
        return Boolean( value > 0 );
    }

    render() {
        return(
            <div className="survey__row">
                <label htmlFor="children">Взрослых</label>
                <div className="survey__input numbers">
                    <div className="number number_minus" onClick={this.setValue.bind(this, Number(this.state.value) -1)}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="2" y="7" width="12" height="2" rx="1" fill="black"/>
                        </svg>
                    </div>
                    <input
                        className={`${this.state.is_valid ? '' : ' invalid'}`}
                        onChange={this.localValueChange}
                        type="number"
                        name={this.props.field_name}
                        id="children"
                        value={this.state.value}
                        min="1"
                        step="1"
                        placeholder="1"
                    />
                    <div className="number number_plus" onClick={this.setValue.bind(this, Number(this.state.value) +1)}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8 2C8.55228 2 9 2.44772 9 3V7H13C13.5523 7 14 7.44772 14 8C14 8.55228 13.5523 9 13 9H9V13C9 13.5523 8.55228 14 8 14C7.44772 14 7 13.5523 7 13V9H3C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7H7V3C7 2.44772 7.44772 2 8 2Z" fill="black"/>
                        </svg>
                    </div>
                </div>
                <span className="validity">Ошибка текст ошибки</span>
            </div>
        );
    }
}
import React from "react";

export default class CommentField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.field_value ?? ""
        }
    }

    componentDidMount() {
        this.props.changeField( this.props.field_name, this.state.value);
    }

    localValueChange = (event) => {
        let val = event.target.value;
        this.setState((state, props) => {
            if( val ) {
                this.props.changeField( this.props.field_name, val );
            }else {
                this.props.changeField( this.props.field_name, "" );
            }

            return {value: val};
        });
    };

    render() {
        return(
            <div className="survey__row">
                <textarea onChange={this.localValueChange} className="survey__input" name={this.props.field_name} placeholder="Ваши пожелания" rows="5" value={this.state.value}></textarea>
            </div>
        );
    }
}
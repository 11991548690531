export const CHANGE_ACTUAL_FIELDS = "CHANGE_ACTUAL_FIELDS";
export const CHANGE_ACTUAL_FIELD = "CHANGE_ACTUAL_FIELD";

export const FINAL_QUESTION_ANSWERED = "FINAL_QUESTION_ANSWERED";

export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_ACTIVE_POPUP = "SET_ACTIVE_POPUP";

export const SEND_QUIZ_RESULT = "SEND_QUIZ_RESULT";

export const RESET_QUIZ = "RESET_QUIZ";

export const CHECK_STEP_PERMISSION = "CHECK_STEP_PERMISSION";
import React from "react";

export const policy = function ( props ) {
    return (
        <div className={'popup'}>
            <h1>
                СОГЛАСИЕ<br/>
                НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
            </h1>
            <h2>Кандидата на вакантные должности ООО «ГАРАНТ-СВ»</h2>
            <br/>
            <p>
                В соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных». Я даю согласие оператору персональных данных (далее – ПДн) ООО «ГАРАНТ-СВ» (298688, Россия, Республика Крым, Ялта, с. Оползневое, улица Генерала Острякова, 9) (далее – «Общество») на обработку (сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), блокирование, удаление, уничтожение) с использованием средств автоматизации или без использования таких средств моих ПДн в целях:
            </p>

            <ul>
                <li>рассмотрения моей кандидатуры для трудоустройства;</li>
                <li>учёта в кадровом резерве;</li>
                <li>проведения аналитических, статистических, маркетинговых исследований и опросов в сфере труда и рынка труда;</li>
                <li>информирования об имеющихся вакантных должностях.</li>
            </ul>

            <p>
                Даю согласие Обществу предоставить доступ к моим ПДн для их обработки (запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение) с использованием средств автоматизации или без использования таких средств следующим третьим лицам, являющимся самостоятельными операторами ПДн: ООО «КИПАРИС2» (298685, Республика Крым, г. Ялта, с. Оползневое, ул. Генерала Острякова, д. 9), ООО «ГОРИЗОНТ-СЕРВИС» (298685, Республика Крым, г. Ялта, с. Оползневое, Севастопольское ш., д.2).
            </p>

            <p>
                В целях: рассмотрения моей кандидатуры для трудоустройства и учёта в кадровом резерве; проведения аналитических, статистических, маркетинговых исследований и опросов в сфере труда и рынка труда; информирования об имеющихся вакантных должностях.
            </p>

            <p>
                ПДн, в отношении которых дается Согласие, включают:
            </p>

            <ul>
                <li>фамилию, имя, отчество;</li>
                <li>дату и место рождения;</li>
                <li>сведения о семейном положении;</li>
                <li>сведения о регистрации по месту жительства и по месту временного пребывания;</li>
                <li>сведения об основном документе, удостоверяющем личность (серия и номер, дата выдачи, место выдачи, код подразделения);</li>
                <li>ИНН;</li>
                <li>СНИЛС;</li>
                <li>сведения об образовании и квалификации работника;</li>
                <li>сведения о работе на текущем и предыдущих местах работы;</li>
                <li>адрес электронной почты;</li>
                <li>абонентский телефонный номер;</li>
                <li>иные данные, предоставленные мной самостоятельно с целью трудоустройства.</li>
            </ul>

            <p>
                Согласие дается на срок, необходимый для рассмотрения моей кандидатуры и принятия решения о приеме меня либо об отказе в приеме меня на работу к соответствующему оператору.
            </p>

            <p>
                Для включения в кадровый резерв Согласие дается на срок с момента направления моей анкеты Обществу до момента отзыва Согласия способом, который указан ниже.
            </p>

            <p>
                Согласие может быть отозвано посредством соответствующего письменного заявления, адресованного Обществу и операторам, указанным в Согласии непосредственно или по почте.
            </p>

            <p>
                Настоящим принимаю, что при отзыве настоящего согласия уничтожение моих персональных данных будет осуществлено в десятидневный срок.
            </p>

        </div>
    );
}

export const success_send = function ( props ) {
    return(
        <div className={'popup'}>
            <h2>Спасибо</h2>
            <p>Наш менеджер свяжется с вами в ближайшее время</p>
        </div>
    );
}
import React from "react";

import {sendQuizResult, resetQuiz} from "../../../storage/actions/Quiz";
import {connect} from "react-redux";
import {openPopup} from "../../../storage/actions/Popup";

class SubmitWindowComponent extends React.Component {
    render() {
        return  (
            <div className="button_wrapper_submit">
                <p className="policy">Нажимая кнопку «Отправить», вы соглашаетесь с
                    <a href={"https://manzherok.travel/upload/policy.pdf"} target={"_blank"}> политикой обработки данных</a>
                </p>
                <div className="survey__row">
                    <button onClick={this.props.resetQuiz} className="button button_reset">
                        <span>Заново</span>
                    </button>
                    {
                        !this.props.is_sended &&
                        <button onClick={this.props.sendData} className="button button_submit">
                            <span>Отправить</span>
                        </button>
                    }
                </div>
            </div>
        );
    }
}

let SubmitWindow = connect(
    state => {return {
        is_sended: state.is_sended
    };},
    dispatch => {return {
        sendData: () => {dispatch(sendQuizResult())},
        openPolicy: () => {dispatch(openPopup( 'policy' ))},
        resetQuiz: () => {dispatch(resetQuiz())}
    };}
)(SubmitWindowComponent);

export default SubmitWindow;
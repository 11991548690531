import React from "react";

import {ReactComponent as CheckSVG} from './svg/check.svg'
import {ReactComponent as CloseSVG} from './svg/close.svg'
import {ReactComponent as ArrowSVG} from './svg/arrow.svg'

class VacanciesField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show_details: false,
            selected_vacancy: props.field_value ?? "",
            vacancies: window.vacancies || {
                vacancy: "Default vacancy",
                schedule: "*/*",
                wage: "от *",
                conditions: [
                    "Condition",
                ],
                responsibilities: [
                    "Responsability"
                ],
                requirements: [
                    "Requirement"
                ]
            },
        }
    }

    hideDetails = () => {
        if(this.state.show_details) {
            this.setState({show_details: false})
        }
    }

    localValueChange = (val) => {
        this.setState((state, props) => {
            if( val ) {
                this.props.changeField( this.props.field_name, val );
            }
            return {value: val};
        });
    };

    renderList = (list) => {
        return <div className="vacancy_details--list">{this.renderListItems(list)}</div>;
    }
    renderListItems = (list) => {
        return list.map((el, i) => {
            return <div className={`vacancy_details--list_item`} key={i}>{el}</div>
        });
    }

    renderVacancies = () => {
        return this.state.vacancies && this.state.vacancies.map((el, i) => {
            return (<React.Fragment key={i}>
                <div className="vacancy"  onClick={this.localValueChange.bind(this, el.vacancy)}>
                    <div className={`vacancy--check${this.state.value === el.vacancy ? ' vacancy--check-checked' : ' vacancy--check-unchecked'}`}>
                        <CheckSVG />
                    </div>
                    <div className="vacancy--name">{el.vacancy}</div>
                    <div className="vacancy--more_details" onClick={(e) => { e.stopPropagation(); this.setState({show_details: el.vacancy}) }}>{window.innerWidth > 380 ? <span>Подробнее</span> : '' } <ArrowSVG /></div>
                </div>
                {
                    this.state.show_details === el.vacancy
                        ? <div className={'vacancy_details'}>
                            <div className="vacancy_details--bg" onClick={this.hideDetails}></div>
                            <div className="vacancy_details--content">
                                <div className="vacancy_details--close" onClick={this.hideDetails}><CloseSVG /></div>
                                <div className="vacancy_details--title">{el.vacancy}</div>

                                {el.wage ? <div className="vacancy_details--list-title">Заработная плата: <span>{el.wage }</span></div> : null}
                                {el.schedule ? <div className="vacancy_details--list-title">Занятость: <span>{el.schedule }</span></div> : null}

                                <div className="vacancy_details--list-title">Условия:</div>
                                {el.conditions ? this.renderList(el.conditions) : null}
                                <div className="vacancy_details--list-title">Обязанности:</div>
                                {el.responsibilities ? this.renderList(el.responsibilities) : null}
                                <div className="vacancy_details--list-title">Требования:</div>
                                {el.requirements ? this.renderList(el.requirements) : null}
                            </div>
                        </div>
                        : null
                }
            </React.Fragment>);
        });
    }

    render() {
        return (
            <div className={'vacancies'}>
                {this.renderVacancies()}
            </div>
        );
    }
}

export default VacanciesField;

import React from "react";
import {connect} from "react-redux";

class SummaryComponent extends React.Component {
    render() {
        return(
            <div className="summary">
                <div className="summary__row">
                    <span>ФИО</span>
                    <span>{this.props.fields.last_name} {this.props.fields.first_name} {this.props.fields.second_name}</span>
                </div>
                <div className="summary__row">
                    <span>Телефон</span>
                    <span>{this.props.fields.tel}</span>
                </div>
                <div className="summary__row">
                    <span>Дата рождения</span>
                    <span>{this.props.fields.birthday_date}</span>
                </div>
                <div className="summary__row">
                    <span>Вакансия</span>
                    <span>{this.props.fields.vacancies}</span>
                </div>
                <div className="summary__row">
                    <span>Проживание</span>
                    <span>{this.props.fields.city}</span>
                </div>
            </div>
        );
    }
}
let Summary = connect(
    state => {return{
        fields: state.actual_fields
    }},
    dispatch => {return{}}
)(SummaryComponent);

export default Summary;
import React from "react";
import InputMask from "react-input-mask";
import {connect} from "react-redux";

class ArrivalDateFieldComponent extends React.Component {
    constructor(props) {
        super(props);
        let date = new Date(),
            day = (date.getDate())  > 9 ? date.getDate() : '0' + (date.getDate()),
            month = (date.getMonth() + 1) > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);

        this.state = {
            value: props.field_value ?? "",
            today: date.getFullYear() + '-' + month + '-' + day,
            error: null
        }
    }
    localValueChange = (event) => {
        let val = event.target.value,
            valid = this.validateValue(val);

        this.setState((state, props) => {
            if( valid ) {
                this.props.changeField( this.props.field_name, val );
            }else if( valid === false && state.is_valid === true ) {
                this.props.changeField( this.props.field_name, null );
            }

            return {value: val, is_valid: valid};
        });
    };

    validateValue = (value) => {
        let error_msg = null;

        if( value < this.state.today ) error_msg = 'Нельзя установить прошедшую дату.';
        if( value > this.props.leave ) error_msg =  'Дата заезда не может быть позднее даты отъезда.';

        if( error_msg !== this.state.error ) this.setState({error: error_msg});

        return Boolean( value.length === 10 && !error_msg );
    }
    render() {
        let min_date = this.state.today,
            max_date = this.props.leave;
        return(
            <div className="survey__row">
                <label htmlFor="children">Дата заезда</label>
                <div className="survey__input numbers">
                    <input onChange={this.localValueChange.bind(this)} min={min_date} max={max_date} className="survey__input" type="date" name={this.props.field_name} placeholder="31 ноября" value={this.state.value} id="datePicker" required />
                    {this.state.error && <span className="validity" style={{opacity: 1}}>{this.state.error}</span>}
                </div>

            </div>
        );
    }
}

let ArrivalDateField = connect(
    state => {return {
        leave: state.actual_fields.hasOwnProperty('leave') ? state.actual_fields.leave : null
    };},
    dispatch => {return {};},
)(ArrivalDateFieldComponent);

export default ArrivalDateField;
import React from "react";
import InputMask from "react-input-mask";
import {connect} from "react-redux";

class BirthdayDateField extends React.Component {
    constructor(props) {
        super(props);
        let date = new Date(),
            day = (date.getDate())  > 9 ? date.getDate() : '0' + (date.getDate()),
            month = (date.getMonth() + 1) > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);

        this.state = {
            value: props.field_value ?? "",
            today: date.getFullYear() + '-' + month + '-' + day,
            error: null
        }
    }
    localValueChange = (event) => {
        let val = event.target.value,
            valid = this.validateValue(val);

        this.setState((state, props) => {
            if( valid ) {
                this.props.changeField( this.props.field_name, val );
            }else if( valid === false && state.is_valid === true ) {
                this.props.changeField( this.props.field_name, null );
            }

            return {value: val, is_valid: valid};
        });
    };

    validateValue = (value) => {
        let error_msg = null;

        if( error_msg !== this.state.error ) this.setState({error: error_msg});

        return Boolean( value.length > 7 && !error_msg );
    }
    render() {
        return(
            <div className="survey__row">
                {/*<label htmlFor="children"></label>*/}
                <div className="survey__input numbers">
                    <InputMask
                        onChange={this.localValueChange.bind(this)}
                        className="survey__input"
                        type="text"
                        name={this.props.field_name}
                        placeholder="dd.mm.yy"
                        value={this.state.value}
                        id="datePicker"
                        mask="99.99.99"
                        maskPlaceholder="dd.mm.yy"
                        required />
                    {this.state.error && <span className="validity" style={{opacity: 1}}>{this.state.error}</span>}
                </div>

            </div>
        );
    }
}

export default BirthdayDateField;
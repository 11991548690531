import React from 'react';
import ReactDOM from 'react-dom';

import thunkMiddleware from "redux-thunk";
import {createStore, applyMiddleware, compose} from "redux";
import {Provider} from "react-redux";

import rootReducer from "./storage";

import QuizPromo from "./components/QuizPromo";

import './main.css';

const storage = createStore(
    rootReducer,
    {
        steps: [
            {
                name: "Какая вакансия вас интересует?",
                fields: ["vacancies"],
                optional: []
            },
            {
                name: "Укажите Ваше ФИО",
                fields: ['last_name', 'first_name', 'second_name'],
                optional: ['second_name']
            },
            {
                name: "Укажите Вашу дату рождения (трудоустройство осуществляется от 18 лет)",
                fields: ["birthday_date"],
                optional: []
            },
            {
                name: "Укажите город, где Вы проживаете",
                fields: ["city"],
                optional: []
            },
            {
                name: "Укажите Ваш номер телефона",
                fields: ["tel"],
                optional: []
            },
        ]
    },
    compose(
        applyMiddleware(thunkMiddleware)
        // , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

ReactDOM.render(
    <Provider store={storage}>
      <React.StrictMode>
        <QuizPromo />
      </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);
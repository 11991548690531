import {CHECK_STEP_PERMISSION, SET_ACTIVE_STEP} from "../constants";


export default function checkNextStepSwitchPermission(state, action) {
    if( action.type === CHECK_STEP_PERMISSION || action.type === SET_ACTIVE_STEP ) {
        let active_step = state.steps[state.active_step_index],
            is_allowed = true;
        active_step.fields.map(field => {
            if( !state.actual_fields.hasOwnProperty(field) && !active_step.optional.includes(field) ) is_allowed = false;
        });
        return is_allowed;
    }
    return false;
}
import React from "react";

import QuizHeader from "../QuizHeader";
import QuizSteps from "../QuizSteps";
import QuizPopups from "../QuizPopups";

class QuizPromo extends React.Component {
    render() {
        return (
            <section className={'section'}>
                <QuizHeader />
                <QuizSteps />
                <QuizPopups />
            </section>
        );
    }
}

export default QuizPromo;
import React from "react";

export default class IntroField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.field_value ? Number(props.field_value) : 1,
            is_valid: Boolean(props.field_value > 0)
        }
    }
    render() {
        return(

            <div className="survey__row is_cascade">
                <iframe src="https://vk.com/video_ext.php?oid=-111826648&id=456239594&hash=f05ff6288b805cc9&hd=2&autoplay=1"  allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="0" allowFullScreen></iframe>                <p>Mriya Resort & SPA — ведущий круглогодичный курорт в Крыму, включающий пятизвездочный отель у моря, 37 вилл, 2 медицинских комплекса, бальнеологический комплекс, «Японский сад», Винный парк, детский парк «Дримвуд», 24 ресторана и бара, собственный пляж, автопарк и яхтенную флотилию.</p>
                <p>Курорт расположен на южном побережье Крыма, в Большой Ялте. Это значит, что вы должны быть готовы к переезду на море. Мы поможем с адаптацией, поиском жилья и регистрацией для иностранных граждан!</p>
                <p>Далее расскажем о ваших условиях и бонусах</p>
            </div>
        );
    }
}
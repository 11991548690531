import React from "react";

import InputMask from 'react-input-mask';

export default class LastNameField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.field_value ?? "",
            is_valid: Boolean(props.field_value)
        }
    }
    localValueChange = (event) => {
        let val = event.target.value,
            valid = this.validateValue(val);

        this.setState((state, props) => {
            if( valid ) {
                this.props.changeField( this.props.field_name, val );
            }else if( valid === false && state.is_valid === true ) {
                this.props.changeField( this.props.field_name, null );
            }

            return {value: val, is_valid: valid};
        });
    };

    validateValue = (value) => {
        return Boolean( value.length > 1 );
    }

    render() {
        return(
            <div className="survey__row">
                <input
                    onChange={this.localValueChange.bind(this)}
                    className={`survey__input${this.state.is_valid ? '' : ' invalid'}`}
                    value={this.state.value}
                    type="text"
                    name={this.props.field_name}
                    id="last_name_field"
                    placeholder="Ваша фамилия" />
                    <span className="validity">Ошибка! Допускается не менее двух букв</span>
                    <div className="validity_svg">
                        <svg className="valid" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.91006 10.4959L3.7071 8.29291C3.31658 7.90239 2.68342 7.90239 2.29289 8.29291C1.90237 8.68343 1.90237 9.3166 2.29289 9.70712L5.29288 12.7071C5.7168 13.131 6.4159 13.0892 6.7863 12.6178L13.7863 4.61786C14.1275 4.18359 14.0521 3.55494 13.6178 3.21372C13.1835 2.87251 12.5549 2.94795 12.2136 3.38222L5.91006 10.4959Z" fill="#219653"/>
                        </svg>
                        <svg className="invalid" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.70705 2.1673C9.0131 2.32032 9.26125 2.56848 9.41428 2.87452L13.8327 11.7114C14.2233 12.4926 13.9067 13.4425 13.1255 13.833C12.9059 13.9428 12.6638 14 12.4183 14H3.5814C2.70802 14 2 13.292 2 12.4186C2 12.1731 2.05716 11.931 2.16695 11.7114L6.58538 2.87452C6.97597 2.09335 7.92587 1.77671 8.70705 2.1673ZM8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11C9 10.4477 8.55228 10 8 10ZM8 5C7.48716 5 7.06449 5.38604 7.00673 5.88338L7 6V8C7 8.55228 7.44772 9 8 9C8.51284 9 8.93551 8.61396 8.99327 8.11662L9 8V6C9 5.44772 8.55228 5 8 5Z" fill="#DE6666"/>
                        </svg>
                    </div>
            </div>
        );
    }
}
import {SEND_QUIZ_RESULT, RESET_QUIZ} from "../constants";
let request_loading = false;
export function sendQuizResult( index ) {
    return (dispatch, getState) => {
        if( !request_loading ) {
            if( window.hasOwnProperty('NGRequest') ) {
                request_loading = true;
                let state = getState();
                window.NGRequest.setData(
                    state.actual_fields,
                    {
                        success: (d) => {
                            request_loading = false;
                            dispatch({
                                type: SEND_QUIZ_RESULT,
                            });
                            // setTimeout(() => {window.location = 'https://www.youtube.com/watch?v=aIa5yNgwrnw';}, 1500)
                        },
                        error: (d) => { /*console.log(d);*/ alert('Ошибка сервера!')}
                    },
                    '/w_mailer/'
                );
                window.NGRequest.send();

            }else alert('Ошибка работы скрипта!');
        }
    }
}

export function resetQuiz( index ) {
    return {
        type: RESET_QUIZ,
    }
}
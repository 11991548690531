import React from "react";

export default class LoyaltyField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.field_value ? Number(props.field_value) : 1,
            is_valid: Boolean(props.field_value > 0)
        }
    }
    render() {
        return(

            <div className="survey__row is_cascade">
                <h3>А теперь расскажем о ваших условиях и бонусах</h3>
                <i>(Да, они реально крутые)</i>
                <ul>
                    <li>Возможность начать работать без опыта</li>
                    <li>Жилье или компенсация съемной квартиры</li>
                    <li>Питание на территории отеля</li>
                    <li>Трансфер до места работы</li>
                    <li>Компенсация переезда в Ялту</li>
                    <li>Официальное трудоустройство</li>
                    <li>Достойная зарплата</li>
                    <li>Возможность карьерного и профессионального роста</li>
                    <li>Регистрация для иностранных граждан</li>
                    <li>Компенсация 50% стоимости занятий на Skyeng</li>
                    <li>Большой и дружный коллектив</li>
                    <li>Клубы и секции по интересам</li>
                    <li>Помощь на всех этапах релокации в Крым</li>
                    <li>Тренинги, семинары и вебинары от Академии Гостеприимства</li>
                    <li>Бонусы и скидки на услуги курорта</li>
                </ul>
                <h3>Особенности компенсации квартиры </h3>
                <p>Допустим, ваша компенсация за жилье составляет 15 000 рублей, а понравившаяся квартира стоит 25 000 рублей в месяц. Вы можете предложить коллеге жить совместно и оплачивать квартиру по 12 500 рублей. Кроме того, вы можете переехать с семьей или друзьями и устроиться на работу в Mriya Resort!</p>

                <h3>Кратко про бонусы и скидки на услуги курорта</h3>
                <p>Бесплатные билеты в «Дримвуд», скидки на посещение Медицинского центра, Института Активного Долголетия, SPA-комплекса, салона красоты, экскурсий по Винному парку и Японскому саду, а также специальная цена на занятия в тренажерном зале.</p>

                <p>Мы заботимся и не экономим на сотрудниках.</p>

                <p><b>Осталось выбрать вакансию!</b></p>
            </div>
        );
    }
}
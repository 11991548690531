import {CHANGE_ACTUAL_FIELD, CHECK_STEP_PERMISSION} from "../constants";

export function changeFieldData( name, value ) {
    return (dispatch, getState) => {
        dispatch({
            type: CHANGE_ACTUAL_FIELD,
            field_name: name,
            field_value: value
        });
        dispatch(initCheckStepPermission());
    };
}

export function initCheckStepPermission( ) {
    return {
        type: CHECK_STEP_PERMISSION
    };
}
import React from "react";

export default class RationField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.field_value ?? "Без питания",
            is_valid: Boolean(props.field_value)
        }
    }
    componentDidMount() {
        this.props.changeField( this.props.field_name, this.state.value);
    }

    localValueChange = (event) => {
        let val = event.target.value,
            valid = this.validateValue(val);

        this.setState((state, props) => {
            if( valid ) {
                this.props.changeField( this.props.field_name, val );
            }else if( valid === false && state.is_valid === true ) {
                this.props.changeField( this.props.field_name, null );
            }

            return {value: val, is_valid: valid};
        });
    };

    validateValue = (value) => {
        return Boolean( value === 'Без питания' || value === 'Завтрак' || value === "Полный пансион" );
    }
    render() {
        return(
            <React.Fragment>
                <div className="survey__row">
                    <label className="survey__input_radio">
                        <input onChange={this.localValueChange} type="radio" name={this.props.field_name} placeholder="Без питания" value="Без питания" checked={Boolean(this.state.value === "Без питания")} required/>
                            <span className="survey__input">Без питания</span>
                    </label>
                </div>
                <div className="survey__row">
                    <label className="survey__input_radio">
                        <input onChange={this.localValueChange} type="radio" name={this.props.field_name} placeholder="Затрак" value="Завтрак" checked={Boolean(this.state.value === "Завтрак")} required/>
                            <span className="survey__input">Завтрак</span>
                    </label>
                </div>
                <div className="survey__row">
                    <label className="survey__input_radio">
                        <input onChange={this.localValueChange} type="radio" name={this.props.field_name} placeholder="Полный пансион" value="Полный пансион" checked={Boolean(this.state.value === "Полный пансион")} required/>
                            <span className="survey__input">Полный пансион</span>
                    </label>
                    <span className="validity">Ошибка</span>
                </div>
            </React.Fragment>
        );
    }
}
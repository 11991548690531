import React from "react";
import {connect} from "react-redux";

class NavigateByNumbersComponent extends React.Component {
    printDots = ( ) => {
        let dots = [];
        this.props.steps.map( (el, i) => {
            let cl = ( this.props.active_step_index === i ) ? ' active' : '';
            dots.push(<div key={i} className={`survey__pagination-item${cl}`}>{i +1}</div>)
        });
        return dots;
    }

    render() {
        return(
            <div className="survey__pagination">
                {this.printDots()}
            </div>
        );
    }
}

let NavigateByNumbers = connect(
    state => {return {
        active_step_index: state.active_step_index,
        steps: state.steps
    }},
    dispatch => {return {}}
)(NavigateByNumbersComponent);

export default NavigateByNumbers;
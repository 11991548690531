import React from "react";
import {connect} from "react-redux";

import {closePopup} from "../../storage/actions/Popup";

class QuizPopupComponent extends React.Component {
    popups = require( './assortedPopups' );
    render() {
        let Popup = this.popups[this.props.actived_popup] || null;
        return Popup && (
            <div className={'quiz_popups'}>
                <div className="quiz_popups--bg" onClick={this.props.closePopup}></div>
                <div className="quiz_popups--wrapper">
                    <div className={`quiz_popups--close`}><button onClick={this.props.closePopup}><span>X</span> Закрыть</button></div>
                    <Popup />
                </div>
            </div>
        );
    }
}

let QuizPopup = connect(
    state => {return {
        actived_popup: state.actived_popup
    }},
    dispatch => {return {
        closePopup: () => {dispatch(closePopup());}
    }}
)(QuizPopupComponent);

export default QuizPopup;
import {SET_ACTIVE_POPUP} from "../constants";

export function openPopup( popup ) {
    return {
        type: SET_ACTIVE_POPUP,
        popup: popup
    }
}

export function closePopup() {
    return openPopup( null );
}
import {CHANGE_ACTUAL_FIELD, CHANGE_ACTUAL_FIELDS, RESET_QUIZ} from "../constants";

export default function changeActualFields( state = {}, action) {
    if( action.type === CHANGE_ACTUAL_FIELDS ) {
        return action.fields;
    }else if( action.type === CHANGE_ACTUAL_FIELD ) {
        if( action.field_value !== null ) {
            state[action.field_name] = action.field_value;
        }else if( state.hasOwnProperty(action.field_name) ) delete state[action.field_name];

    }else if( action.type === RESET_QUIZ ) return {};

    return state;
}
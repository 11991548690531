import React from "react";

import NavigateByNumbers from "./NavigateByNumbers";
import NavigationButtons from "./NavigationButtons";
import StepData from "./StepData";
import Summary from "./Summary";
import SubmitWindow from "./SubmitWindow";
import {connect} from "react-redux";

class QuizStepsComponent extends React.Component {
    render() {
        let items = [];
        if( !this.props.is_completed ) {
            items.push(<NavigateByNumbers key={1} />);
            items.push(<StepData key={2} />);
            items.push(<NavigationButtons key={3} />);
        }else {
            items.push(<Summary key={1} />);
            items.push(<SubmitWindow key={2} />);
        }

        return (
            <div className="survey">
                {items}
            </div>
        );
    }
}

let QuizSteps = connect(
    state => {return {
        is_completed: state.is_completed
    };},
    dispatch => {return {}}
)(QuizStepsComponent);
export default QuizSteps;
import changeActualFields from './reducers/changeActualFields';
import changeCompletedState from "./reducers/changeCompletedState";
import changeActionStepIndex from "./reducers/changeActionStepIndex";
import checkNextStepSwitchPermission from "./reducers/checkNextStepSwitchPermission";
import changeSendDataStatus from "./reducers/changeSendDataStatus";
import changeActivePopup from "./reducers/changeActivePopup";

export default function rootReducer( state = {}, action) {return {
    is_completed: changeCompletedState( state.is_completed, action ),
    is_sended: changeSendDataStatus( state.is_sended, action ),
    active_step_index: changeActionStepIndex(state.active_step_index, action),
    steps: state.steps,
    actived_popup: changeActivePopup(state.actived_popup, action),
    next_step_allowed: checkNextStepSwitchPermission(state, action),
    actual_fields: changeActualFields(state.actual_fields, action)
};};
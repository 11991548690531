import {CHANGE_ACTUAL_FIELD, FINAL_QUESTION_ANSWERED, SET_ACTIVE_STEP} from "../constants";
import {initCheckStepPermission} from "./Fields";

export function setActiveStepIndex( index ) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_ACTIVE_STEP,
            step_index: index
        });
        dispatch(initCheckStepPermission());
    };

}

export function answerFinalQuestion( ) {
    return {
        type: FINAL_QUESTION_ANSWERED
    }
}

import React from "react";
import {connect} from "react-redux";


class QuizHeaderComponent extends React.Component {
    render() {
        return(
            <header className="header">
                <div className="header__logo">
                    <img src={`/imgs/logo.png`} />
                </div>
                <div className="header__text">
                    { this.props.is_completed
                        ? <h2>Спасибо</h2>
                        : null //<h2>Mriya Resort & SPA — форма соискателей</h2>
                    }
                    { this.props.is_completed
                        ? <p>Наш менеджер свяжется с вами в ближайшее время</p>
                        : <p>Заполните форму, чтобы откликнуться на вакансию. Ознакомьтесь с условиями, обязаностями, требованиями и графиком для выбора подходящей вакансии. После отправки формы, вам позвонит наш специалист, будьте готовы ответить на звонок!<br/><br/>Если у вас возникли вопрос, звоните по номеру <a href={"tel:88002227841"}>+7 (913) 692 00-85</a><br/>Я ознакомлен с <a href=https://xn--80akcrghjp.xn--p1ai/politics>Политикой обработки персональных данных</a>, и соглашаюсь на обработку моих персональных данных в целях прохождения кадрового отбора в ООО «Всесезонный курорт «Манжерок».<br/></p>
                    }
                </div>
            </header>
        );
    }
}
let QuizHeader = connect(
    state => { return {
        is_completed: state.is_sended
    } },
    dispatch => { return {} }
)(QuizHeaderComponent);

export default QuizHeader;
import React from "react";
import {connect} from "react-redux";
import {changeFieldData, initCheckStepPermission} from "../../../storage/actions/Fields";

class StepDataComponent extends React.Component {
    fields = require('./assortedFields')

    componentDidMount() {
        this.props.init();
    }

    changeField = ( field_name, value ) => {
        this.props.changeFieldData(field_name, value);
    }

    printFields = () => {
        let fields = [];
        this.props.active_step.fields.map(( field_name, i ) => {
            let Field = this.fields[field_name] || null,
                value = this.props.actual_fields.hasOwnProperty(field_name) ? this.props.actual_fields[field_name] : null;
            Field && fields.push(<Field key={i} field_name={field_name} field_value={value} changeField={this.changeField} />);
        });
        return fields;
    }

    render() {
        return(
            <form className="swiper-wrapper">
                <fieldset className="survey__content">
                    <h3>{this.props.active_step.name}</h3>
                    {this.printFields()}
                </fieldset>
            </form>
        );
    }
}
let StepData = connect(
    state => {return {
        active_step: state.steps[state.active_step_index],
        actual_fields: state.actual_fields
    }},
    dispatch => {return {
        changeFieldData: (name, value) => {dispatch(changeFieldData(name, value))},
        init: ( ) => {dispatch(initCheckStepPermission())},
    }}
)(StepDataComponent);

export default StepData;
import {SET_ACTIVE_POPUP, SEND_QUIZ_RESULT} from "../constants";

export default function (state = null, action ) {
    if( action.type === SET_ACTIVE_POPUP ) {
        return action.popup;
    }
    if( action.type === SEND_QUIZ_RESULT ) {
        return 'success_send'
    }
    return state;
}